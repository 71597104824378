<template>
  <div class="page-container -scrollable text">
      <iframe v-show="iframeShow" ref="iframe" id="iframe" frameborder=0 class="iframe" scrolling="auto" :src="iframeSrc" width="100%" height="100%" />
  </div>
</template>

<style lang="scss">
.page-container {
  .iframe {
    width: 100%;
    min-height: calc(100vh - 23px);
  }
}
</style>

<script>
import { messages } from "@/lib/emitter"
//import { pageTest } from "../lib/page"
import { Logger } from "../lib/logger"
const log = Logger("app:app-page")

export default {
  name: "app-page",
  components: {},
  data() {
    return {
      iframeShow: false,
      iframeSrc: "",
    }
  },
  created() {
    //console.log('app-page created')
    //this.iframeSrc = 'https://www.baidu.com'
  },
  mounted() {
    //console.log('app-page mounted')
    messages.on("loadPage", (page) => {
      this.iframeSrc = page
      this.iframeShow = true
    })
    
    messages.on("unloadPage", () => {
      this.iframeShow = false
      this.iframeSrc = ""
    })
  },
  beforeDestroy() {
    //console.log('app-page beforeDestroy')
  },
  methods: {
    selectAll() {
      setTimeout(() => {
        let el = this.$refs.input
        el.select()
      }, 0)
    },
  },
}
</script>
